import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store'
import { RefreshToken } from '@/func'

const routes = [
    {
        path: '/',
        redirect: '/history',
        component: () => import('@/components/CallHistory')
    },
    {
        path: '/history',
        component: () => import('@/components/CallHistory')
    },
    {
        path: '/agentstat',
        component: () => import('@/components/AgentsStat')
    },
    {
        path: '/queuestat',
        component: () => import('@/components/QueueStat')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/Login')
    },
    {
        path: '/users',
        component: () => import('@/components/Users')
    },
    {
        path: '/operator',
        component: () => import('@/components/Operator')
    },
    {
        path: '/user/:id',
        component: () => import('@/components/User')
    },
    {
        path: '/entry',
        component: () => import('@/components/Entry')
    },
    {
        path: '/numlist',
        component: () => import('@/components/NumList')
    },
    {
        path: '/extens',
        component: () => import('@/components/Extens')
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/components/NotFound')
    },

]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach((to) => {
    if (to.name !== 'Login') {
        const loggedTime = useStore().getLogged
        if (loggedTime == 0) {
            return { path: "/login" }
        }
        //console.log(loggedTime)
        if (Date.now() - loggedTime > 600000) {
            RefreshToken()
        }
    }
})

export default router;
