<template>
  <q-layout view="lHh Lpr lFf">
    <q-header>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title>
          {{ store.bind.label }}
        </q-toolbar-title>
        <q-separator dark vertical />
        <q-btn
           icon="person"
           @click="UserEdit"
           flat
           round
        />
        <q-toolbar-title>
          {{ store.user.name }}
        </q-toolbar-title>
        <q-separator class="q-mr-lg" dark vertical />
        <q-btn 
          icon="logout"
          @click="UserLogout"
          flat
          round
          />
      </q-toolbar>
    </q-header>

    <q-drawer
      :width=350
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-list v-if="store.user.id != 0">
        <q-item v-if="store.user.role == 'pbxadmin'">
          <q-item-section avatar>
            <q-icon name="contact_phone" />
          </q-item-section>
          <q-item-section>
            <q-select v-model="bind" :options="store.binds" option-label="value" label="BIND" />
          </q-item-section>
        </q-item>
        <q-item clickable to="/history">
          <q-item-section avatar>
            <q-icon name="list" />
          </q-item-section>
          <q-item-section>
            <q-item-label>История вызовов</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="store.bind.callcenter" clickable :disable="store.user.role == 'user'" to="/agentstat">
          <q-item-section avatar>
            <q-icon name="bar_chart" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Статистика работы операторов</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="store.bind.callcenter" clickable :disable="store.user.role == 'user'" to="/queuestat">
          <q-item-section avatar>
            <q-icon name="bar_chart" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Статистика очередей вызовов</q-item-label>
          </q-item-section>
        </q-item>
        <q-expansion-item
          expand-separator
          icon="schema"
          label="Конфигуратор АТС"
        >
          <q-list dense>
            <q-item :inset-level=1 clickable to="/entry">
              <q-item-section avatar>
                <q-icon name="phone" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Городские номера</q-item-label>
              </q-item-section>
            </q-item>
            <q-item :inset-level=1 clickable to="/numlist">
              <q-item-section avatar>
                <q-icon name="menu" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Списки номеров</q-item-label>
              </q-item-section>
            </q-item>
            <q-item :inset-level=1 clickable to="/extens">
              <q-item-section avatar>
                <q-icon name="dialer_sip" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Внутренние номера</q-item-label>
              </q-item-section>
            </q-item>
            <q-item disable :inset-level=1 clickable to="/schedules">
              <q-item-section avatar>
                <q-icon name="pending_actions" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Расписания</q-item-label>
              </q-item-section>
            </q-item>
            <q-item disable :inset-level=1 clickable to="/ivrs">
              <q-item-section avatar>
                <q-icon name="format_list_numbered" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Голосовые меню</q-item-label>
              </q-item-section>
            </q-item>
            <q-item disable :inset-level=1 clickable to="/queues">
              <q-item-section avatar>
                <q-icon name="view_timeline" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Очереди</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-expansion-item>
        <q-item clickable :disable="store.user.role != 'admin' && store.user.role != 'pbxadmin'" to="/users">
          <q-item-section avatar>
            <q-icon name="people" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Пользователи</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="store.bind.callcenter" clickable :disable="store.user.agent_id == 0" to="/operator">
          <q-item-section avatar>
            <q-icon name="support_agent" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Рабочее место оператора</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable @click="syncData" >
          <q-item-section avatar>
            <q-icon name="sync" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Обновить данные</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <q-page-container>
        <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, watch } from 'vue'
import router from '@/router'
import { useStore } from '@/store'
import { storeToRefs } from 'pinia'

export default {
  name: 'MainApp',

  components: {
  
  },

  setup () {

    const store = useStore()
    const { bind } = storeToRefs(store)
    const UserLogout = () => {
      store.ResetUser()
      router.push('/login')
    }

    const UserEdit = () => {
      router.push('/user/' + store.user.id)
    }
    const syncData = () => {
      store.setBindData()
    }

    watch(bind, (newValue) => {
        if (newValue.value) {
         // console.log('Новое значение bind: ' + newValue.value)
          store.SetBind(newValue)
        }
    })

    return {
      store,
      bind,
      UserLogout,
      UserEdit,
      syncData,
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
