import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import config from '@/config'
import router from '@/router'
import { useStore } from '@/store'

export const headers = () => {
  return {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': 'Bearer ' + useStore().getToken
  }
}

export function TimeFormat(sec) {
  let h = sec/3600 ^ 0
  let m = (sec-h*3600)/60 ^ 0
  let s = sec-h*3600-m*60
  let t = (h>0?h+"ч ":"")+(m>0?m+"м ":"")+s+"с"
  return t
}

export const errhandler = async (response) => {
  if (response.status === 401) {
    router.push("/login")
  } else {
    const errtext = await response.text()
    Notify.create({message: errtext, type: "negative", position: "top", timeout: 3000})
  }
  } 

export const ServLogin = async (login, password) => {
    const response = await fetch(`${config.server}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ login: login, password: password })
    })
    if (response.ok) {
        const result = await response.json()
        const store = useStore()
        store.SetUser(result)
        store.SetStat()
        router.push('/history')
    } else {
      Notify.create({message: "Ошибка авторизации", type: "negative", position: "top", timeout: 2000})
    }
}

export const RefreshToken = async () => {
  const response = await fetch(`${config.server}/refresh_token`, {
    method: 'GET',
    headers: headers(),
  })
  if (response.ok) {
    const result = await response.json()
    const store = useStore()
    store.SetToken(result.token)
  } else {
    router.push('/login')
  }
}

export const SetFilter = (filter) => {
  //console.log(filter)
  let f = {
    tfrom: filter.dfrom + " " + filter.tfrom,
    tto: filter.dto + " " + filter.tto,
    cid: filter.cid ? "%" + filter.cid + "%" : "%",
    did: filter.did ? "%" + filter.did + "%" : "%",
    queue: filter.queue && filter.queue.value ? "%" + filter.queue.value + "%" : "%",
    exten: filter.exten ? "%" + filter.exten + "%" : "%",
    agent: filter.agent && filter.agent.value ? "%Agent:" + filter.agent.value + "%" : "%",
    dir: filter.dir && filter.dir.value ? filter.dir.value : "",
    status: filter.status && filter.status.value ? filter.status.value : "",
  }
  return f
}

export const GetStat = async (filter, path) => {
  const response = await fetch(`${config.server}/${path}`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify(filter)
  })
  if (response.ok) {
      const result = await response.json()
      return result
  } else {
      await errhandler(response)
  }
  return
}

export const GetList = async (obj) => {
  const response = await fetch(`${config.server}/${obj}`, {
      method: 'GET',
      headers: headers()
  })
  if (response.ok) {
      const result = await response.json()
      //console.log("func", result)
      return result
  } else {
      await errhandler(response)
  }
  return []
}

// ---------------------------  users ----------------------------------------------------------------

export async function GetUsers() {
  const response = await fetch(`${config.server}/users`, {
    method: 'GET',
    headers: headers(),
  })
  if (response.ok) {
    const userList = await response.json()
    return userList
    //console.log(userList)
  } else {
    await errhandler(response)
  }
}

export async function AddUser(user) {
  const response = await fetch(`${config.server}/user`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(user)
  })
  if (response.ok) {
    Notify.create({message: "Пользователь создан", type: "positive", position: "top", timeout: 2000})
    router.push('/users')
  } else {
    await errhandler(response)
  }
}

export async function UpdateUser(user) {
    const response = await fetch(`${config.server}/user/${user.id}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(user)
  })
  if (response.ok) {
    Notify.create({message: "Данные пользователя обновлены", type: "positive", position: "top", timeout: 2000})
    //router.push('/users')
  } else {
    await errhandler(response)
  }
}

export async function GetUser(id) {
  const response = await fetch(`${config.server}/users/${id}`, {
    method: 'GET',
    headers: headers(),
  })
  if (response.ok) {
    const user = await response.json()
    user.password = ''
    return user
  } else {
    await errhandler(response)
  }
}

export async function DeleteUser(id) {
      const response = await fetch(`${config.server}/user/${id}`, {
        method: 'DELETE',
        headers: headers(),
      })
      if (response.ok) {
        Notify.create({message: "Пользователь удален", type: "positive", position: "top", timeout: 2000})
        //router.push('/users')
      } else {
        await errhandler(response)
      }  
}

export function GetWS(bind, id, queues) {
  let ws = new WebSocket(`ws://${config.eventserver}/wsagent/${bind}/${id}`)
  const store = useStore()
  ws.onerror = function(event) {
      console.log("ws error:",event)
      Notify.create({message: "Ошибка соединения с сервером", type: "negative", position: "top", timeout: 3000})
      ws.close()
      ws = undefined
  }
  ws.onopen = () => {
      //console.log(event)
      console.log("Successfully connected to the event server...")
      let qstr = queues.map((v) => v.value).join(":")
      ws.send("getAgentStatus:" + qstr)
  }
  ws.onclose = () => {
      //console.log(event)
      console.log("ws closed, reconnect...")
/*       setTimeout(() => {
          GetWS(id)
      }, 10000) */
  }
  ws.onmessage = (event) => {
      //console.log("incoming message", event)
      if (event.data == null || event.data == "null" || event.data == "") {
          return
      }
      const data = JSON.parse(event.data)
      switch (data.Event) {
          case "PanelInfo":
              store.SetQueuesInfo(data)
              break
          case "queueentry":
          case "dialexten":
          case "queueanswer":
          case "callend":
          case "chanend":  
              store.UpdateQueues(data)
              break
          case "pause_on":
          case "pause_off":
          case "agent_out":
          case "agent_in":
              store.UpdateAgentInfo(data)
              break
      }
  }
  return ws
}

export async function PostObj (req, data) {
  const response = await fetch(`${config.server}/${req}`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify(data)
  })
  if (response.ok) {
    const obj = await response.json()
    Notify.create({message: "Данные обновлены", type: "positive", position: "top", timeout: 2000})
    return obj
  } else {
    await errhandler(response)
  }
}

export function DeleteObj (obj, id, name) {
  Dialog.create({
    title: 'Подтвердите действие',
    message: `Удалить объект ${name}?`,
    cancel: true,
    persistent: true
  }).onOk( async () => {
      const response = await fetch(`${config.server}/obj/${obj}/${id}`, {
        method: 'DELETE',
        headers: headers(),
      })
      if (response.ok) {
        Notify.create({message: `Объект ${name} id=${id} удален`, type: "positive", position: "top", timeout: 2000})
      } else {
        await errhandler(response)
      }
  })
}
