import { defineStore } from 'pinia'
import { GetStat, GetList, TimeFormat, GetWS } from '@/func'
import date from 'quasar/src/utils/date.js';

export const useStore = defineStore('statdata', {

    state: () => ({
        bind: {label: "", value: "", callcenter: false},
        binds: [],
        agents: [],
        queues: [],
        user: {
            bind: "",
            id: 0,
            name: "",
            role: "user",
            logged: 0,
            token: "",
            agent_id: 0,
        },
        filter: {
            dfrom: "",
            dto: "",
            tfrom: "00:00",
            tto: "23:59",
            cid: "",
            did: "",
            queue: {label: "", value: ""},
            exten: "",
            agent: {label: "", value: ""},
            dir: {label: "", value: ""},
            status: {label: "", value: ""}
        },
        callhistory: [],
        agentstat: [],
        agentcharts: {
            date: [],
            calls: [],
            billsec: [],
            avgbillsec: [],
            maxbillsec: [],
            avgwait: [],
            maxwait: []
        },
        queuestat: [],
        queuecharts: {
            date: [],
            answered: [],
            missed: [],
            avgwait: [],
            maxwait: [],
            avglen: [],
            maxlen: [],
        },
        agent: {
            status: "свободен",
            phone: "",
            ans_calls: 0,
            work_time: "-"
        },
        call: {
            start: "",
            queue: "",
            src: "",
            name: "",
            dst: "",
            wait: "",
            talk: "",
            uid: ""
        },
        ws: WebSocket,
        entries: [],
        extens: [],
        numbers: [],
    }),

    getters: {
        getToken: (state) => state.user.token,
        getLogged: (state) => state.user.logged,
        getFilter: (state) => {
            let f = {
                bind: state.bind.value,
                tfrom: state.filter.dfrom + " " + state.filter.tfrom,
                tto: state.filter.dto + " " + state.filter.tto,
                cid: state.filter.cid ? "%" + state.filter.cid + "%" : "%",
                did: state.filter.did ? "%" + state.filter.did + "%" : "%",
                queue: state.filter.queue && state.filter.queue.value ? "%" + state.filter.queue.value + "%" : "%",
                exten: state.filter.exten ? "%" + state.filter.exten + "%" : "%",
                agent: state.filter.agent && state.filter.agent.value ? "%Agent:" + state.filter.agent.value + "%" : "%",
                dir: state.filter.dir && state.filter.dir.value ? state.filter.dir.value : "",
                status: state.filter.status && state.filter.status.value ? state.filter.status.value : "",
              }
              return f
        },
    },

    actions: {
        async SetStat() {
            if (this.user.role == "pbxadmin") {
                this.binds = await GetList("binds")
                //this.bind = this.binds.find((val) => val.value == this.bind.value)
            }
            await this.setBindData()
            const today = date.formatDate(Date.now(), 'YYYY-MM-DD')
            this.filter.dfrom = today
            this.filter.dto = today
        },
        async setBindData() {
            if (this.bind.value != "") {
                if (this.bind.callcenter) {
                    this.agents = await GetList(`agents/${this.bind.value}`) 
                    this.queues = await GetList(`queues/${this.bind.value}`)
                }
                this.entries = await GetList(`entries/${this.bind.value}`)
                this.numbers = await GetList(`numbers/${this.bind.value}`)
                this.extens = await GetList(`extens/${this.bind.value}`)
            }
        },
        async GetHistory(page) {
            const f = this.getFilter
            switch (page) {
                case "agenthistory":
                    this.filter.dfrom = date.formatDate(Date.now(), 'YYYY-MM-DD')
                    this.filter.tfrom = "00:00:00"
                    this.filter.dto = this.filter.dfrom
                    this.filter.tto = "23:59:59"
                    /* falls through */
                case "callhistory":
                    var result = await GetStat(f, "callhistory")
                    if (result == undefined) break
                    result.forEach(v => {
                        v.queue = v.queue ? this.queues.find(q => q.value == v.queue) || {value: "", label: ""} : {value: "", label: ""}
                        v.agent = v.agent ? this.agents.find(q => q.value == v.agent) || {value: "", label: ""} : {value: "", label: ""}
                        v.status = (v.status == "ANSWER" || v.status == "CONTINUE") ? "ANSWER" : "NOANSWER"
                        v.dir = v.dir == "incoming" ? "incoming" : "outgoing"
                        // v.billsec = {value: v.duration - v.wait, label: TimeFormat(v.duration - v.wait)}
                        v.billsec = {value: v.billsec, label: TimeFormat(v.billsec)}
                        // v.duration = {value: v.duration, label: TimeFormat(v.duration)}
                        v.wait = {value: v.wait, label: TimeFormat(v.wait)}
                    });
                    this.callhistory = result
                    break
                case "queues":                    
                    result = await GetStat(f, "queuestat")
                   // console.log(result)
                    if (result == undefined) break
                    result.queues.forEach(v => {
                        v.queue = v.queue ? this.queues.find(a => a.value == v.queue) || {value: "", label: ""} : {value: "", label: ""}
                        v.avgwait = {value: v.avgwait, label: TimeFormat(v.avgwait)}
                        v.maxwait = {value: v.maxwait, label: TimeFormat(v.maxwait)}
                    });
                    this.queuestat = result.queues
                    this.queuecharts = result.stat
                    break
                case "agents":
                    result = await GetStat(f, "agentstat")
                    if (result == undefined) break
                    result.members.forEach(v => {
                    v.agent = v.id ? this.agents.find(a => a.value == v.id) || {value: "", label: ""} : {value: "", label: ""}
                    v.billsec = {value: v.billsec, label: TimeFormat(v.billsec)}
                    v.avgbillsec = {value: v.avgbillsec, label: TimeFormat(v.avgbillsec)}
                    v.avgwait = {value: v.avgwait, label: TimeFormat(v.avgwait)}
                    v.maxwait = {value: v.maxwait, label: TimeFormat(v.maxwait)}
                    });
                    this.agentstat = result.members
                    this.agentcharts = result.stat
                    break
            }
        },
        SetUser(data) {
            this.bind = data.bind
            this.user.bind = data.bind.value
            this.user.id = data.id
            this.user.name = data.name + " " + data.last_name
            this.user.role = data.role
            this.user.logged = Date.now()
            this.user.token = data.token
            this.user.agent_id = data.agent_id
            //this.UpdateAgent()
        },
        SetToken(token) {
            this.user.logged = Date.now()
            this.user.token = token
        },
        async SetBind(val) {
            this.bind = val
            this.setBindData()
            if (this.bind.callcenter) {
                this.UpdateAgent()
            }
        },
        UpdateAgent() {
            if (this.user.agent_id != undefined && this.user.agent_id != 0 && this.bind.value != "") {
                if (this.ws.readyState == WebSocket.OPEN) {
                    this.ws.close()
                }
                this.ws = GetWS(this.bind.value, this.user.agent_id, this.queues)
            }
        },
        UpdateAgentInfo(data) {
            if (data.agent.id != this.user.agent_id && data.exten != this.agent.phone) {
                return
            }
            switch (data.Event) {
                case "agent_in":
                case "agent_out":
                    //console.log("queue ", data.Event)
                    var qu = this.queues.find(v => v.value == data.bind + "_" + data.queue)
                    if (qu != undefined) {         
                        if (data.Event == "agent_in") {
                            qu.reg_status = "reg"
                            this.agent.work_time = data.agent.work_time
                            this.agent.phone = data.agent.phone
                        } else {
                            qu.reg_status = ""
                            if (!this.queues.find(v => v.reg_status != "")) {
                                this.agent.work_time = ""
                            }
                        }
                    } 
                    break

                case "pause_on":
                case "pause_off":
                    this.queues.forEach(qu => {
                       // console.log("queue", qu)
                        if (data.Event == "pause_on" && qu.reg_status == "reg") {
                            qu.reg_status = "pause"
                        } else if (data.Event == "pause_off" && qu.reg_status == "pause") {
                            qu.reg_status = "reg"
                        }
                    })
                    break
            }
        },
        SetQueuesInfo(e) {
            this.agent.ans_calls = 0
            e.queues.forEach(eq => {
                let q = this.queues.find(v => v.value === eq.queue)
                if (q != undefined) {
                    q.calls = []
                    eq.entries.forEach(ent => {
                        q.calls.push({
                            start: Math.floor(Date.now() / 1000) - ent.wait,
                            uid: ent.uid,
                            src: ent.src,
                            dst: ent.dst,
                            cidname: ent.cidname
                        })
                        if (ent.exten === e.member.phone && e.member.status === "вызов") {
                            this.call.uid = ent.uid
                            this.call.src = ent.src
                            this.call.cidname = ent.cidname
                            this.call.queue = q.label
                            this.call.wait = ent.wait
                            this.call.talk = 0
                        }
                    })
                    q.ans_calls = eq.ans_calls
                    q.reg_status = eq.reg_status
                    this.agent.ans_calls += eq.ans_calls
                } 
            })
            this.agent.phone = e.member.phone
            this.agent.status = e.member.status
            this.agent.work_time = e.member.work_time
        },
        UpdateQueues(e) {
           // console.log(e.Event)
            switch (e.Event) {
                case "queueentry":
                    var q = this.queues.find(v => v.value === e.bind + "_" + e.queue)
                    if (q == undefined) {
                        return
                    }
                    q.calls.push({
                        uid: e.uid,
                        start: Math.floor(Date.now() / 1000),
                        src: e.cid,
                        cidname: e.cidname,
                        dst: e.did
                    })
                   // console.log("calls", q.calls)
                    break
                case "dialexten":
                    if (this.agent.phone === e.exten) {
                        this.agent.status = "вызов"
                        this.call.src = e.cid
                        this.call.cidname = e.cidname
                        this.call.uid = e.uid
                        this.queues.every(aq => {
                            let c = aq.calls.find(v => v.uid === e.uid)
                            if (c != undefined) {
                                this.call.queue = aq.label
                                this.call.dst = c.dst
                                this.call.wait = Math.floor(Date.now() / 1000) - c.start
                                this.call.talk = 0
                                return false
                            } 
                            return true
                        })
                    }
                    break
                case "queueanswer":
                    if (this.agent.phone === e.exten) {
                        this.agent.status = "разговаривает"
                        this.agent.ans_calls ++
                        this.queues.every(aq => {
                            let i = aq.calls.findIndex(v => v.uid === e.uid)
                            if (i >= 0) {
                                aq.calls.splice(i, 1)
                                return false
                            } 
                            return true
                        })
                    }
                    break
                case "callend":
                case "chanend":
                    if (this.call.uid === e.uid) {
                        this.call.src = ""
                        this.call.dst = ""
                        this.call.uid = ""
                        this.call.cidname = ""
                        this.call.queue = ""
                        this.call.wait = 0
                        this.call.talk = 0
                        this.call.formatWait = ""
                        this.call.formatTalk = ""
                        this.agent.status = "свободен"
                    } 
                    this.queues.every(aq => {
                        let i = aq.calls.findIndex(v => v.uid === e.uid)
                        if (i >= 0) {
                            aq.calls.splice(i, 1)
                            return false
                        } 
                        return true
                    })

            }
        },
        updateTimers(t) {
            this.queues.forEach(q => {
                if (q.calls.length > 0) {
                    q.wait = t - q.calls[0].start
                    q.formatWait = TimeFormat(q.wait) 
                } else {
                    q.wait = 0
                    q.formatWait = ""
                }
            })
            if (this.agent.status == "разговаривает") {
                this.call.talk ++
                this.call.formatTalk = TimeFormat(this.call.talk)
            } else if (this.agent.status == "вызов") {
                this.call.wait ++
                this.call.formatWait = TimeFormat(this.call.wait)
            }
        },
        sendAMI(queue, action) {
            this.ws.send(action + ":" + this.bind.value + ":" + this.agent.phone + ":" + queue + ":" + this.user.agent_id)
        },
        ResetUser() {
            this.$reset()
        }
    }
})

